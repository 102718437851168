import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/browser';
import { GrowYourBusinessModule } from './grow-your-business.module';

if(environment.production) {
  window.console.log = function() {}
  enableProdMode()
}

if(environment.production && environment.SENTRY_DSN) {
  Sentry.init({
    dsn: environment.SENTRY_DSN
  });
}

platformBrowserDynamic().bootstrapModule(GrowYourBusinessModule)
  .catch(err => console.error(err));